import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { sentryToken } from '@consensus/shared/shared/analytics/data-access';
import { NotificationType } from '@consensus/connect/shared/notifications/domain';
import { openInNewTab } from '@lib/helpers';
import { Capacitor } from '@capacitor/core';
import { openLinkInCapacitorHandler } from '@consensus/connect/ufa/capacitor/util-safe-links';

const isValidExternalUrl = (url: string | null): boolean => {
	if (!url) {
		return false;
	}
	try {
		new URL(url);
		return true; // We could parse the URL and hence assume that it is a valid, external URL
	} catch {
		return false;
	}
};

export const notificationLinkHandler = () => {
	const router = inject(Router);
	const sentry = inject(sentryToken);
	return async ({
		notificationId,
		notificationType,
		url,
		socialFeedPostId,
		storylinesPostId,
		storylinesFeedId,
		eventSlug,
	}: {
		notificationId: string;
		notificationType: NotificationType;
		url: string | null;
		socialFeedPostId: string | null;
		storylinesPostId: string | null;
		storylinesFeedId: string | null;
		eventSlug: string;
	}) => {
		switch (notificationType) {
			case NotificationType.SocialFeedPost: {
				router.navigate([
					'./',
					'event',
					eventSlug,
					'socialfeed',
					socialFeedPostId,
				]);
				break;
			}
			case NotificationType.StorylinesPostPublication: {
				router.navigate([
					'./',
					'event',
					eventSlug,
					'feeds',
					storylinesFeedId,
					'posts',
					storylinesPostId,
				]);
				break;
			}
			case NotificationType.Link: {
				if (!url) {
					sentry.captureMessage(
						`Tried to open link for Link notification ID ${notificationId}, but link was empty`
					);
					return;
				}
				if (isValidExternalUrl(url)) {
					const isCapacitor = await Capacitor.isNativePlatform();
					isCapacitor
						? router.navigateByUrl(`/event/${eventSlug}`).then(() =>
								openLinkInCapacitorHandler({
									href: url,
									defaultAction: href => openInNewTab(href),
								})
						  )
						: await openInNewTab(url);
				} else {
					let internalRoute = url;
					if (internalRoute.startsWith('./')) {
						// As we use navigateByUrl here, we don't accept absolute internal URLs (as the urls are basically already treated as such)
						internalRoute = internalRoute.replace('./', '/');
					}
					if (!internalRoute.startsWith('/')) {
						// Ensure that internalRoute starts with '/'
						internalRoute = '/' + internalRoute;
					}
					// Prefix internal route with the event slug if the internal route doesn't already start with /event/
					if (!internalRoute.startsWith('/event/')) {
						// Note no '/' between eventSlug and internalRoute as we have ensured above that internalRoute starts with '/'
						internalRoute = `/event/${eventSlug}${internalRoute}`;
					}
					router
						.navigateByUrl(internalRoute)
						.then(() => {
							// Check after 1 second if we have succeeded in redirecting to internalRoute, if not, retry.
							setTimeout(() => {
								if (!router.url.includes(internalRoute)) {
									router.navigateByUrl(internalRoute);
								}
							}, 1000);
						})
						.catch(() => {
							// We try again
							router.navigateByUrl(internalRoute);
						});
				}
				break;
			}
			case NotificationType.Text: {
				sentry.captureMessage(
					`Tried to open link for Text notification ID ${notificationId}, which should not be possible`
				);
				break;
			}
			default:
				notificationType satisfies never;
				break;
		}
	};
};
