import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FileModel } from '@lib/files';
import { lastValueFrom, Subject, filter } from 'rxjs';
import { environmentToken } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class FileCacheService {
	readonly #mediaServer = inject(environmentToken).mediaServer;
	readonly #httpClient = inject(HttpClient);

	#cache: { [key: string]: FileModel } = {};
	#loading: { [key: string]: Promise<FileModel | null> } = {};
	#timeout: { [key: string]: ReturnType<typeof setTimeout> } = {};
	#expiry: { [key: string]: Date } = {};

	#reloads = new Subject<string>();

	async #getSignedUrl(url: string): Promise<FileModel> {
		const data = await lastValueFrom(
			this.#httpClient.get<FileModel>(
				this.#mediaServer + '/' + url.replace(/^\/+/, '')
			)
		);
		if (!data) {
			throw new Error(`Failed to get media file: ${url}`);
		}
		if (!data.url) {
			const split = url.split('?');

			const blob = await lastValueFrom(
				this.#httpClient.get(
					`${this.#mediaServer}/${split[0].replace(
						/^\/+/,
						''
					)}/read?time=${new Date().getTime()}${
						split.length > 1 ? `&${split[1]}` : ''
					}`,
					{
						responseType: 'blob',
					}
				)
			);
			data.blob = blob;
			if (blob) {
				data.url = URL.createObjectURL(blob);
			}
		}
		return data;
	}

	async getFileUrl(url: string, noCache = false): Promise<string> {
		const file = await this.getFile(url, noCache);
		return file.url;
	}

	async getFile(url: string, noCache = false): Promise<FileModel> {
		if (!url) {
			throw new Error('url not provided to getFile');
		}
		if (noCache) {
			return await this.#getSignedUrl(url);
		}

		let file: FileModel | null;

		if (Object.prototype.hasOwnProperty.call(this.#cache, url)) {
			file = this.#cache[url];
			if (this.#expiry[url] && this.#expiry[url] < new Date()) {
				this.refreshCache(url);
				return await this.getFile(url);
			}
		} else if (Object.prototype.hasOwnProperty.call(this.#loading, url)) {
			file = await this.#loading[url];
		} else {
			this.#loading[url] = this.#getSignedUrl(url);
			file = await this.#loading[url];

			const match = file?.url.match(/expires=(\d+)/i);

			if (match && match.length > 1) {
				this.#expiry[url] = new Date(+match[1] * 1000 - 10000);
				clearTimeout(this.#timeout[url]);
				this.#timeout[url] = setTimeout(
					() => this.refreshCache(url),
					new Date(+match[1] * 1000).getTime() - new Date().getTime() - 10000
				);
			}

			delete this.#loading[url];
			if (file) {
				this.#cache[url] = file;
			}
		}

		if (file == null) {
			throw Error('Image failed to load');
		}

		return file;
	}

	refreshCache(url: string) {
		delete this.#loading[url];
		delete this.#cache[url];
		delete this.#expiry[url];
		this.#reloads.next(url);
	}

	getFileType(url: string): string | null {
		return (this.#cache?.[url] && this.#cache[url]?.fileType) ?? null;
	}

	onReload(url: string, func: () => void) {
		return this.#reloads.pipe(filter(x => x === url)).subscribe(func);
	}
}
