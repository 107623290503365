import { inject, Injectable } from '@angular/core';
import { Event } from '@consensus/shared/shared/event/domain';
import { dispatch, dispatchAsync } from '@lib/redux';
import { resetStores } from '@lib/redux';
import { Store } from '@ngrx/store';
import { SlugQuery } from '@shared/models';
import {
	clearEvent,
	selectClient,
	selectEvent,
	loadClient,
	loadEvent,
} from '@store/scope';
import { WebsocketService } from './websocket.service';
import {
	selectTheme,
	loadPageTheming,
	loadThemeById,
	Theme,
} from '@store/theming';
import { Client } from '@consensus/shared/shared/client/domain';
import { AcademyPermissionService } from '@consensus/academy/iam/data-access';
import { ConnectSharedDataAccessWebsocketService } from '@consensus/connect/shared/data-access-websocket';

@Injectable({ providedIn: 'root' })
export class ScopeService {
	readonly #academyPermissionService = inject(AcademyPermissionService);
	readonly #wsService = inject(ConnectSharedDataAccessWebsocketService);
	readonly #websocketService = inject(WebsocketService);
	readonly #store = inject(Store);
	readonly client$ = this.#store.select(selectClient);
	client!: Client | null;
	event!: Event | null;
	theme!: Theme | null;
	#themeId: Theme['id'] | null = null;

	constructor() {
		this.#store.select(selectEvent).subscribe(e => (this.event = e));
		this.client$.subscribe(c => (this.client = c));
		this.#store.select(selectTheme).subscribe(t => (this.theme = t));
	}

	async startEventFromSlug(slug: string) {
		return await this.#startEvent({ slug }, false);
	}

	async startEventFromId(id: string) {
		return await this.#startEvent({ id }, true);
	}

	async #startEvent(query: SlugQuery, isCms: boolean) {
		await this.loadEvent(query, isCms);

		await this.#setupEvent(isCms);

		return { client: this.client, event: this.event };
	}

	async loadEvent(query: SlugQuery, isCms: boolean) {
		if (
			(query.slug && this.event?.slug != query.slug) ||
			(query.id && this.event?.id != query.id)
		) {
			if (this.event) {
				dispatch(resetStores, 'Event');
				await this.#disposeEvent();
			}

			// Allow console.debug usage
			// eslint-disable-next-line no-restricted-syntax
			console.debug(`Loading event: "${query.slug ?? query.id}"...`);
			await dispatchAsync(loadEvent, query);
		}

		if (this.event.themeId) {
			const loadedTheme = await this.#loadTheme(this.event.themeId);
			if (loadedTheme && !isCms) {
				await dispatchAsync(loadPageTheming);
			}
		} else {
			await this.#loadTheme(this.client.themeId);
			if (!isCms) {
				await dispatchAsync(loadPageTheming);
			}
		}

		return { client: this.client, event: this.event };
	}

	async leaveEvent() {
		dispatch(resetStores, 'Event');
		await this.#disposeEvent();
	}

	async startClientFromSlug(slug: string) {
		return await this.#startClient({ slug }, false);
	}

	async startClientFromId(id: string) {
		return await this.#startClient({ id }, true);
	}

	async #startClient(query: SlugQuery, isCms) {
		await this.loadClient(query);

		await this.#setupClient(isCms);

		return this.client;
	}

	async loadClient(query: SlugQuery) {
		if (
			(query.slug && this.client?.slug != query.slug) ||
			(query.id && this.client?.id != query.id)
		) {
			if (this.client) {
				dispatch(resetStores, 'Client');
				dispatch(resetStores, 'Event');

				await this.#disposeClient();
			}

			// Allow console.debug usage
			// eslint-disable-next-line no-restricted-syntax
			console.debug(`Loading client: "${query.slug ?? query.id}"...`);
			await dispatchAsync(loadClient, query);
		} else {
			dispatch(clearEvent);
		}

		await this.#loadTheme(this.client.themeId);

		return this.client;
	}

	async leaveClient() {
		dispatch(resetStores, 'Client');
		await this.#disposeClient();
	}

	async #loadTheme(id: string) {
		if (this.#themeId != id) {
			if (this.theme.id != id) {
				// Allow console.debug usage
				// eslint-disable-next-line no-restricted-syntax
				console.debug(`Loading Theme...`);
				await dispatchAsync(loadThemeById, id).then(
					theme => (this.#themeId = theme.id)
				);
			} else {
				this.#themeId = this.theme.id;
			}
			return true;
		}
		return false;
	}

	async #setupEvent(isCms: boolean) {
		if (this.#wsService.eventId && this.#wsService.eventId == this.event?.id) {
			if (this.#wsService.connectionState$.value === 'NotConnected') {
				await this.#wsService.closeConnection();
				// Allow console.debug usage
				// eslint-disable-next-line no-restricted-syntax
				console.debug(`Starting event Socket...`);
				await this.#websocketService.startConnection(this.event.id, isCms);
			}
		} else {
			await this.#wsService.closeConnection();
			// Allow console.debug usage
			// eslint-disable-next-line no-restricted-syntax
			console.debug(`Starting event Socket...`);
			await this.#websocketService.startConnection(this.event.id, isCms);
		}

		await this.#academyPermissionService.loadEvent(
			this.client.id,
			this.event.id
		);
	}

	async #disposeEvent() {
		await this.#wsService.closeConnection();
		this.#academyPermissionService.clearEvent();
	}

	async #setupClient(_isCms: boolean) {
		await this.#academyPermissionService.loadClient(this.client.id);
	}

	async #disposeClient() {
		this.#academyPermissionService.clearClient();
	}
}
