/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import {
	ChangeDetectorRef,
	Component,
	inject,
	Input,
	OnDestroy,
} from '@angular/core';
import { DownloadService } from '@consensus/shared/shared/files/data-access-files';
import { FileCacheService } from '@consensus/shared/shared/files/data-access-files';
import { Subscription } from 'rxjs';
import { FileModel, getFileIcon } from '@lib/files';
import { mimeToFileType } from '@lib/files';
import { FileTypes } from '@consensus/shared/shared/files/domain';
import { BypassSecurityTrustResourceUrlPipe } from '@shared/pipes';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
	selector: 'co-encoded-gview',
	templateUrl: './encoded-gview.component.html',
	styleUrls: ['./encoded-gview.component.scss'],
	// For now we will not enable OnPush, but instead just markForCheck whenever appropriate
	// changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, MatButtonModule, BypassSecurityTrustResourceUrlPipe],
})
export class EncodedGviewComponent implements OnDestroy {
	readonly #changeDetectonRef = inject(ChangeDetectorRef);
	readonly #downloadService = inject(DownloadService);
	readonly #fileService = inject(FileCacheService);
	#reload = new Subscription();
	loading = false;
	failed = false;

	fileData: FileModel;
	encodedUrl: string;
	icon: string;
	canPreview = false;

	preview = false;

	#src = '';
	@Input()
	set src(src: string) {
		if (src === this.#src) {
			return;
		}
		this.preview = false;
		this.#src = src;
		this.#loadData();

		this.#changeDetectonRef.markForCheck();
	}

	@Input()
	set academyResource([moduleId, resourceId]: [string, string]) {
		this.src = `academy/module/${moduleId}/resource/${resourceId}`;
	}

	@Input()
	set driveFileId(id: string) {
		this.src = `materials/${id}`;
	}

	async #loadData() {
		this.loading = true;

		this.#changeDetectonRef.markForCheck();

		try {
			this.fileData = await this.#fileService.getFile(this.#src);
			this.icon = getFileIcon(mimeToFileType(this.fileData.fileType));
			const fileType = mimeToFileType(this.fileData.fileType);
			this.encodedUrl = encodeURIComponent(this.fileData.url);
			this.canPreview =
				!this.fileData.blob &&
				this.encodedUrl &&
				previewTypes.includes(fileType);

			this.failed = false;
		} catch (e) {
			this.fileData = null;
			this.failed = true;
		} finally {
			this.loading = false;

			if (this.#reload) {
				this.#reload.unsubscribe();
			}
			this.#reload = this.#fileService.onReload(this.#src, () =>
				this.#loadData()
			);

			this.#changeDetectonRef.markForCheck();
		}
	}

	ngOnDestroy(): void {
		this.#reload.unsubscribe();
	}

	downloadFile() {
		if (!this.fileData) {
			return;
		}
		if (this.fileData.blob) {
			return this.#downloadService.downloadFileFromBlob(
				this.fileData.blob,
				this.fileData.fileName
			);
		} else {
			return this.#downloadService.downloadFileFromUrl(
				this.fileData.url,
				this.fileData.fileName
			);
		}
	}
}

const previewTypes = [FileTypes.Word, FileTypes.Excel, FileTypes.Powerpoint];
